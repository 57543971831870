<header>
  <div class="logo-container">
    <img src="assets/images/image.png" alt="Fill logo" class="logo" />
    <img src="assets/images/image-6.png" alt="FIFCO logo" class="logo" />
  </div>
  <div class="header-right">
    <span class="header-text">CONOCE MÁS DE FILL, AQUÍ:</span>
    <a href="https://www.youtube.com/watch?v=O3X9eXPXmsw" target="_blank" class="social-link">
      <img src="assets/images/image-7.png" alt="YouTube" class="social-icon" />
    </a>
    <img src="assets/images/image-3.png" alt="Mascota" class="mascot" />
  </div>
</header>
